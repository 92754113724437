<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="cost-title">Cost {{ currentCostName }}</span>
    </v-card-title>

    <v-card-text v-show="isCostLoading">
      <cost-card-shimmer-vue />
    </v-card-text>

    <v-card-text v-show="!isCostLoading">
      <div class="d-flex align-center">
        <h1 class="text-3xl font-weight-semibold cost-amount">
          Ksh {{ currentCostAbbreviated }}
        </h1>

        <div class="d-flex align-center mb-n3">
          <v-icon
            size="40"
            :class="{'success--text': currentCost > previousCost, 'error--text': currentCost < previousCost}"
            class="cost-icon"
          >
            {{ differenceIcon }}
          </v-icon>
          <span
            v-show="!isNaN(percentageDifference) && percentageDifference !== 0"
            class="text-base font-weight-medium ms-n2"
            :class="{'success--text': currentCost > previousCost, 'error--text': currentCost < previousCost}"
          >
            {{ percentageDifference }}%
          </span>
        </div>
      </div>

      <h4 class="mt-2 font-weight-medium text--secondary">
        Compared to Ksh {{ previousCostAbbreviated }} {{ previousCostName }}
      </h4>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuUp, mdiMenuDown } from '@mdi/js'
import CostCardShimmerVue from './CostCardShimmer.vue'

export default {
  components: {
    CostCardShimmerVue,
  },
  props: {
    currentCostName: {
      type: String,
      required: true,
    },
    currentCost: {
      type: Number,
      default: 0,
    },
    currentCostAbbreviated: {
      type: String,
      default: null,
    },
    previousCostName: {
      type: String,
      required: true,
    },
    previousCost: {
      type: Number,
      default: 0,
    },
    previousCostAbbreviated: {
      type: String,
      default: null,
    },
    isCostLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      icons: { mdiMenuUp, mdiMenuDown },
    }
  },
  computed: {
    differenceIcon() {
      if (this.currentCost > this.previousCost) {
        return this.icons.mdiMenuUp
      }
      if (this.currentCost < this.previousCost) {
        return this.icons.mdiMenuDown
      }

      return null
    },
    percentageDifference() {
      const difference = Math.abs(this.currentCost - this.previousCost)
      const referenceValue = this.previousCost

      if (referenceValue === 0) {
        return this.currentCost === 0 ? 0 : Infinity // Handle division by zero
      }

      return Math.round((difference / referenceValue) * 100)
    },

  },
}
</script>

<style>
.cost-title {
  font-size: 18px;
}

.cost-amount {
  font-size: 28px;
  font-weight: bold;
}

.cost-icon {
  margin-right: 8px;
}

.success--text {
  color: var(--success); /* Use Vuetify's success color */
}

.error--text {
  color: var(--error); /* Use Vuetify's error color */
}
</style>
